<template>
  <div class="tickets-table">
    <v-card>
      <v-card-title
        v-if="userId"
        class="mb-0 pb-1"
      >
        Ticket Details
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-title>
        <v-container>
          <v-row>
            <v-col class="col-md-3">
              <refresh-button
                :loading="isTicketsRefreshing"
                @click="refreshTickets"
              />
            </v-col>
            <v-col
              class="col-md-6 text-md-right"
            >
              <table-filter
                :table-name="'tickets'"
                :show-date-filter="false"
                :show-ticket-status-filter="true"
                :show-ticket-assignment-filter="true"
                :show-ticket-priority-filter="true"
                :show-ticket-category-filter="true"
                :filters="tableFilters"
                :default-date-picker-date="defaultDatePickerDate"
                label="All Assignments"
                @filter="applyFilters"
                @filterLabelChange="onFilterLabelChange"
              />
            </v-col>
          </v-row>
          <v-row
            justify="center"
            class="mb-0"
          >
            <v-col class="col-md-6 text-center">
              <search-input
                v-model="search"
                :filters="filteredSearchFilters"
                :active-filter="searchColumn"
                @filter-change="onSearchFilterChange"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-data-table
        :headers="filteredHeaders"
        :items="tickets"
        :loading="isTicketsLoading"
        :server-items-length="pagination.totalItems"
        :options.sync="options"
        class="clickable-data-table"
        :footer-props="footerProps"
        @click:row="onRowClick"
      >
        <template v-slot:item.customer.user.name="{ item }">
          {{ item.customer.user.name }}
        </template>
        <template v-slot:item.ticket_number="{ item }">
          {{ item.ticket_number }}
        </template>
        <template v-slot:item.subject="{ item }">
          {{ item.subject }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            class="ma-2"
            :color="getStatusChipColor(item.status_label)"
          >
            {{ item.status_label }}
          </v-chip>
        </template>
        <template v-slot:item.priority="{ item }">
          <v-chip
            class="ma-2"
            :color="getPriorityChipColor(item.priority_label)"
          >
            {{ item.priority_label }}
          </v-chip>
        </template>
        <template v-slot:item.category="{ item }">
          {{ item.category_label }}
        </template>
        <template v-slot:item.assigned_to="{ item }">
          {{ item.assigned_to ? item.assigned_to.name : 'Unassigned' }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | formatDate }}
        </template>
        <template v-slot:item.updated_at="{ item }">
          {{ item.updated_at | formatDate }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                icon
                v-bind="attrs"
                @click.stop="viewTicketOnNewTab(item.id)"
                v-on="on"
              >
                <v-icon v-bind="attrs">
                  {{ icons.mdiOpenInNew }}
                </v-icon>
              </v-btn>
            </template>
            <span>View ticket on new tab</span>
          </v-tooltip>
          <v-tooltip
            v-if="can('ticket-edit')"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                ma-2
                v-bind="attrs"
                text
                icon
                @click.stop="editTicket(item)"
                v-on="on"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiPencil }}
                </v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip
            v-if="can('ticket-delete')"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                ma-2
                v-bind="attrs"
                text
                icon
                @click.stop="showDeleteTicketDialog(item.id)"
                v-on="on"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiDelete }}
                </v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider />
    </v-card>
    <add-ticket-dialog
      :show-dialog="showAddTicketDialog"
      :ticket="ticket"
      :user-id="userId"
      @close="$emit('close-ticket-dialog'); ticket = {}"
      @ticketAdded="refreshTickets"
    />
    <assign-ticket-dialog
      :show-dialog="showAssignTicketDialog"
      :ticket="ticketToAssign"
      @ticket-assigned="onTicketAssigned"
      @close="showAssignTicketDialog = false"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :title="'Delete ticket?'"
      :agree-text="'Delete'"
      :message="'Are you sure you want to delete this ticket? This action can not be reversed.'"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteTicket()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import {
  mdiOpenInNew, mdiDownload, mdiDelete, mdiAccountArrowRight, mdiPencil,
} from '@mdi/js'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import TableFilter from '@/components/partials/table-filter/index.vue'
import SearchInput from '@/components/partials/SearchInput.vue'
import AddTicketDialog from '@/components/dialogs/AddTicketDialog.vue'
import AssignTicketDialog from '@/components/dialogs/AssignTicketDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    RefreshButton,
    TableFilter,
    SearchInput,
    AddTicketDialog,
    AssignTicketDialog,
    ConfirmDialog,
  },
  mixins: [hasPermission],
  props: {
    ticketsUrl: {
      type: String,
      default: null,
    },
    showAddTicketDialog: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      tickets: [],
      isTicketsLoading: true,
      isTicketsRefreshing: false,
      pagination: {
        totalItems: 0,
      },
      defaultDatePickerDate: '',
      options: {
        sortBy: ['created_at'],
        sortDesc: [true],
      },
      footerProps: {
        'items-per-page-options': [10, 25, 50, 100],
        'items-per-page-text': 'Items per page:',
      },
      search: '',
      showAssignTicketDialog: false,
      ticketToAssign: {},
      tableFilters: {
        ticketStatus: {
          open: false,
          in_progress: false,
          resolved: false,
          closed: false,
        },
        ticketPriority: {
          1: false,
          2: false,
          3: false,
          4: false,
        },
        ticketCategory: {
          billing: false,
          technical: false,
          general_inquiry: false,
          service_request: false,
        },
        ticketAssignment: 'all_assignments',
      },
      searchFilters: [
        { text: 'Ticket Number', value: 'ticket_number' },
        { text: 'Subject', value: 'subject' },
        { text: 'Customer Name', value: 'customer.user.name' },
      ],
      searchColumn: 'ticket_number',
      icons: {
        mdiOpenInNew,
        mdiDownload,
        mdiDelete,
        mdiAccountArrowRight,
        mdiPencil,
      },
      showConfirmDeleteDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      toDelete: '',
      exportTitle: 'Tickets',
      ticket: {},
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
    headers() {
      const headers = [
        { text: 'Ticket Number', value: 'ticket_number' },
        { text: 'Customer', value: 'customer.user.name' },
        { text: 'Subject', value: 'subject' },
        { text: 'Issue Type', value: 'category' },
        { text: 'Status', value: 'status' },
        { text: 'Priority', value: 'priority' },
        { text: 'Assigned To', value: 'assigned_to' },
        { text: 'Created', value: 'created_at' },
        { text: 'Last Updated', value: 'updated_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ]

      if (this.selectedStation === 'all') {
        headers.splice(headers.length - 1, 0, { text: 'Station', value: 'customer.station.name' })
      }

      return headers
    },
    filteredHeaders() {
      if (this.userId) {
        return this.headers.filter(header => header.text !== 'Customer')
      }

      return this.headers
    },
    exportHeaders() {
      return this.filteredHeaders.filter(header => header.value !== 'actions')
    },
    filteredSearchFilters() {
      if (this.userId) {
        return this.searchFilters.filter(filter => filter.text !== 'Customer Name')
      }

      return this.searchFilters
    },
    constructCompleteTicketsUrl() {
      const params = new URLSearchParams({
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        sortBy: this.options.sortBy,
        sortDirection: this.options.sortDesc[0] ? 'desc' : 'asc',
        search: this.search,
        searchColumn: this.searchColumn,
        fromDate: this.tableFilters.dates.fromDate,
        toDate: this.tableFilters.dates.toDate,

        // Status filters
        open: this.tableFilters.ticketStatus.open,
        inProgress: this.tableFilters.ticketStatus.in_progress,
        resolved: this.tableFilters.ticketStatus.resolved,
        waitingOnCustomer: this.tableFilters.ticketStatus.waiting_on_customer,
        waitingOnAgent: this.tableFilters.ticketStatus.waiting_on_agent,

        // Priority filters
        priority1: this.tableFilters.ticketPriority[1],
        priority2: this.tableFilters.ticketPriority[2],
        priority3: this.tableFilters.ticketPriority[3],
        priority4: this.tableFilters.ticketPriority[4],

        // Category filters
        billing: this.tableFilters.ticketCategory.billing,
        technical: this.tableFilters.ticketCategory.technical,
        generalInquiry: this.tableFilters.ticketCategory.general_inquiry,
        serviceRequest: this.tableFilters.ticketCategory.service_request,

        // Assignment filter
        assignment: this.tableFilters.ticketAssignment,
      })

      // Conditional inclusion of userId or stationId
      if (this.userId) {
        params.append('userId', this.userId)
      } else {
        params.append('stationId', this.selectedStation)
      }

      return `${this.ticketsUrl}?${params.toString()}`
    },

  },
  watch: {
    options: {
      handler() {
        this.fetchTickets()
      },
      deep: true,
    },
    search() {
      this.fetchTickets()
    },
    selectedStation() {
      this.fetchTickets()
    },
    searchColumn() {
      if (this.search) {
        this.fetchTickets()
      }
    },
  },
  mounted() {
    if (this.userId) {
      this.searchColumn = 'ticket_number'
      this.defaultDatePickerDate = 'Last 1 year'
    } else {
      this.defaultDatePickerDate = 'Last 30 days'
    }
    this.fetchTickets()
  },
  methods: {
    fetchTickets: _.debounce(function () {
      this.isTicketsLoading = true
      axios
        .get(this.constructCompleteTicketsUrl)
        .then(response => {
          this.tickets = response.data.data
          this.pagination.totalItems = response.data.total
        })
        .catch(error => {
          console.error('Error fetching tickets:', error)
        })
        .finally(() => {
          this.isTicketsLoading = false
          this.isTicketsRefreshing = false
        })
    }, 500),
    getStatusChipColor(status) {
      switch (status) {
        case 'Open':
          return '#FFC107' // Yellow color for Open
        case 'In Progress':
          return '#2196F3' // Blue color for In Progress
        case 'Resolved':
          return '#4CAF50' // Green color for Resolved
        case 'Closed':
          return '#9E9E9E' // Grey color for Closed
        default:
          return '#448AFF' // Default color for other statuses
      }
    },
    getPriorityChipColor(priority) {
      switch (priority) {
        case 'Low':
          return '#4CAF50' // Green color for Low
        case 'Medium':
          return '#FFC107' // Yellow color for Medium
        case 'High':
          return '#FF9800' // Orange color for High
        case 'Urgent':
          return '#F44336' // Red color for Urgent
        default:
          return '#448AFF' // Default color for other priorities
      }
    },
    refreshTickets() {
      this.isTicketsRefreshing = true
      this.fetchTickets()
    },
    applyFilters(filters) {
      this.tableFilters = { ...this.tableFilters, ...filters }
      this.fetchTickets()
    },
    onRowClick(row) {
      this.$router.push({ name: 'tickets.show', params: { id: row.id } })
    },
    onSearchFilterChange(filter) {
      this.searchColumn = filter
    },
    onFilterLabelChange(label) {
      this.exportTitle = label
    },
    viewTicketOnNewTab(id) {
      const routeData = this.$router.resolve({
        name: 'tickets.show',
        params: { id },
      })
      window.open(routeData.href, '_blank')
    },
    editTicket(ticket) {
      this.ticket = ticket
      this.$emit('show-edit-ticket-dialog')
    },
    showDeleteTicketDialog(id) {
      this.showConfirmDeleteDialog = true
      this.toDelete = id
    },
    onTicketAssigned() {
      this.showAssignTicketDialog = false
      this.refreshTickets()
    },
    deleteTicket() {
      this.isConfirmDeleteDialogButtonLoading = true
      axios
        .delete(`tickets/${this.toDelete}`)
        .then(() => {
          this.refreshTickets()
          this.$toast.success('Ticket deleted successfully')
        }).catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
        })
    },
  },
}
</script>
