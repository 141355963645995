var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tickets-table"},[_c('v-card',[(_vm.userId)?_c('v-card-title',{staticClass:"mb-0 pb-1"},[_vm._v(" Ticket Details "),_c('v-spacer')],1):_vm._e(),_c('v-card-title',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"col-md-3"},[_c('refresh-button',{attrs:{"loading":_vm.isTicketsRefreshing},on:{"click":_vm.refreshTickets}})],1),_c('v-col',{staticClass:"col-md-6 text-md-right"},[_c('table-filter',{attrs:{"table-name":'tickets',"show-date-filter":false,"show-ticket-status-filter":true,"show-ticket-assignment-filter":true,"show-ticket-priority-filter":true,"show-ticket-category-filter":true,"filters":_vm.tableFilters,"default-date-picker-date":_vm.defaultDatePickerDate,"label":"All Assignments"},on:{"filter":_vm.applyFilters,"filterLabelChange":_vm.onFilterLabelChange}})],1)],1),_c('v-row',{staticClass:"mb-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"col-md-6 text-center"},[_c('search-input',{attrs:{"filters":_vm.filteredSearchFilters,"active-filter":_vm.searchColumn},on:{"filter-change":_vm.onSearchFilterChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1),_c('v-data-table',{staticClass:"clickable-data-table",attrs:{"headers":_vm.filteredHeaders,"items":_vm.tickets,"loading":_vm.isTicketsLoading,"server-items-length":_vm.pagination.totalItems,"options":_vm.options,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.customer.user.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.user.name)+" ")]}},{key:"item.ticket_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ticket_number)+" ")]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subject)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.getStatusChipColor(item.status_label)}},[_vm._v(" "+_vm._s(item.status_label)+" ")])]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.getPriorityChipColor(item.priority_label)}},[_vm._v(" "+_vm._s(item.priority_label)+" ")])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.category_label)+" ")]}},{key:"item.assigned_to",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.assigned_to ? item.assigned_to.name : 'Unassigned')+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.updated_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.viewTicketOnNewTab(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',_vm._b({},'v-icon',attrs,false),[_vm._v(" "+_vm._s(_vm.icons.mdiOpenInNew)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("View ticket on new tab")])]),(_vm.can('ticket-edit'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"ma-2":"","text":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.editTicket(item)}}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]):_vm._e(),(_vm.can('ticket-delete'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"ma-2":"","text":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.showDeleteTicketDialog(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_vm._e()]}}])}),_c('v-divider')],1),_c('add-ticket-dialog',{attrs:{"show-dialog":_vm.showAddTicketDialog,"ticket":_vm.ticket,"user-id":_vm.userId},on:{"close":function($event){_vm.$emit('close-ticket-dialog'); _vm.ticket = {}},"ticketAdded":_vm.refreshTickets}}),_c('assign-ticket-dialog',{attrs:{"show-dialog":_vm.showAssignTicketDialog,"ticket":_vm.ticketToAssign},on:{"ticket-assigned":_vm.onTicketAssigned,"close":function($event){_vm.showAssignTicketDialog = false}}}),_c('confirm-dialog',{attrs:{"show-dialog":_vm.showConfirmDeleteDialog,"is-agree-button-loading":_vm.isConfirmDeleteDialogButtonLoading,"title":'Delete ticket?',"agree-text":'Delete',"message":'Are you sure you want to delete this ticket? This action can not be reversed.'},on:{"cancel":function($event){_vm.showConfirmDeleteDialog = false},"agree":function($event){return _vm.deleteTicket()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }